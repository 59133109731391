import React, { useState, useEffect, useCallback, useRef } from "react";
import { IoIosOptions, IoMdDownload } from "react-icons/io";
import "./style.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { MdOutlineDeleteForever } from "react-icons/md";
import { IoPersonAdd } from "react-icons/io5";

export const Setting = () => {
  const [showOptions, setShowOptions] = useState(false); // 옵션 표시 여부 관리
  const [selectedPeriod, setSelectedPeriod] = useState("전체");
  const [selectedTeam, setSelectedTeam] = useState("전체");
  const selectedPeriodRef = useRef(selectedPeriod);
  const selectedTeamRef = useRef(selectedTeam);
  const navigate = useNavigate();
  const code = localStorage.getItem("code");
  const periods = ["전체", "1일", "1주", "1개월", "1년"];
  const teams = ["전체", "고라니", "너구리", "다람쥐", "비둘기"];
  const is_person = ["전체", "필터링 실행 X", "필터링 실행", "필터링 결과 사람음성 탐지"];
  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지 관리
  const [currentPage2, setCurrentPage2] = useState(1);
  const resultsPerPage = 10; // 페이지당 결과 수
  const [searchResults, setSearchResults] = useState([]);
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("파일관리");
  const [selectedFiles, setSelectedFiles] = useState([]); // 파일 선택 체크박스 상태 관리

  // 회원 추가 모달
  const [showModal, setShowModal] = useState(false);
  const [newUser, setNewUser] = useState({
    name: "",
    userId: "",
    password: "",
    team: "고라니",
    userType: "0", 
  });
  const toggleModal = () => {
    if (showModal === true) {
      setNewUser({
        name: "",
        userId: "",
        password: "",
        team: "고라니",
        userType: "0",
      });
    }
    setShowModal(!showModal);
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser({ ...newUser, [name]: value });
  };

  const handleAddUser = async () => {
    try {
      const response = await axios.post("/api/add", {
        name: newUser.name,
        userId: newUser.userId,
        password: newUser.password,
        team: newUser.team,
        userType: parseInt(newUser.userType), // Ensure userType is an integer
      });

      if (response.status === 200) {
        alert("회원이 성공적으로 추가되었습니다!");
        setNewUser({
          name: "",
          userId: "",
          password: "",
          team: "고라니",
          userType: "0",
        });
        setShowModal(false); // Close modal on success
        fetchData();
      } else {
        alert("회원 추가 실패: " + response.data.message);
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.message || "알 수 없는 오류가 발생했습니다.";
      alert(errorMessage);
    }
  };

  const handleDeleteUser = async (data) => {
    const confirmDelete = window.confirm(`'${data.user_id}'님을 삭제하시겠습니까?`)

    if (confirmDelete) {
      try {
        const response = await axios.post("/api/delete", {
          userId: data.user_id,
        });
        if (response.status === 200) {
          alert("해당 회원 정보가 삭제되었습니다.");
          fetchData();
        } else {
          alert("회원 추가 실패: " + response.data.message);
        }
      } catch (error) {
        console.error("회원 삭제 중 오류 발생:", error);
        alert("오류 발생! 다시 시도해주세요.");
      }
    }
  }
  // 옵션 선택 
  const handleChange = (event) => {
    setSelectedOption(event.target.value); // 선택된 값을 업데이트
  };

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  const handlePeriodSelect = (period) => {
    setSelectedPeriod(period);
  };

  const handleTeamSelect = (team) => {
    setSelectedTeam(team);
  };

  const resetOptions = () => {
    setSelectedPeriod("전체");
    setSelectedTeam("전체");
    selectedPeriodRef.current = '전체';
    selectedTeamRef.current = '전체';
    fetchData(); // 초기화 후 데이터를 다시 가져옴
  };

  const handleSearch = () => {
    fetchData(); // 검색 옵션에 따라 데이터를 가져옴
  };

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.post("/api/setting", {
        params: {
          period: selectedPeriodRef.current,
          team: selectedTeamRef.current,
        },
      });
      
      
      if (response && response.status === 200) {
        const { results, results2 } = response.data;
        setSearchResults(results);
        setUserData(results2);
      }
    } catch (error) {
      console.error("데이터를 가져오는 중 오류 발생:", error);
    }
  },[]);

  useEffect(() => {
    if (code !== '3') {
      alert("권한이 없습니다.");
      navigate("/dashboard/home");
    }
    fetchData(); // 컴포넌트가 마운트될 때 fetchData 호출
  }, [fetchData, code, navigate]);

  useEffect(() => {
    selectedPeriodRef.current = selectedPeriod;
  }, [selectedPeriod]);
  
  useEffect(() => {
    selectedTeamRef.current = selectedTeam;
  }, [selectedTeam]);

  const indexOfLastResult = currentPage * resultsPerPage;
  const indexOfFirstResult = indexOfLastResult - resultsPerPage;
  const currentResults = searchResults.slice(
    indexOfFirstResult,
    indexOfLastResult
  );

  const totalPages = Math.ceil(searchResults.length / resultsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDownload = (fileUrl) => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = ""; 
    document.body.appendChild(link); 
    link.click();
    document.body.removeChild(link);
  };

  // AI 필터링
  const handleFiltering = async (fileUrl) => {
    try {
      // 선택된 옵션을 쿼리 파라미터로 전달
      const response = await axios.post("/api/filtering", {
        params: {
          url: fileUrl,
        },
      });
      if (response.status === 200) {
        fetchData();
      }
    } catch (error) {
      console.error("필터링 중 오류 발생:", error);
    }
  };

  const handleCheckboxChange = (fileId) => { // 체크박스 상태 변경 핸들러
    setSelectedFiles((prevSelected) =>
      prevSelected.includes(fileId)
        ? prevSelected.filter((id) => id !== fileId)
        : [...prevSelected, fileId]
    );
  };

  const handleSelectAll = (e) => {
    const currentPageIds = currentResults.map((result) => result.file_id);

    if (e.target.checked) {
      // 현재 페이지의 모든 항목 선택
      setSelectedFiles((prevSelected) => [
        ...prevSelected,
        ...currentPageIds.filter((id) => !prevSelected.includes(id)), // 중복 방지
      ]);
    } else {
      // 현재 페이지의 항목 해제
      setSelectedFiles((prevSelected) =>
        prevSelected.filter((id) => !currentPageIds.includes(id))
      );
    }
  };

  const isAllSelected = currentResults.every((result) =>
    selectedFiles.includes(result.file_id)
  );

  const handleBulkFiltering = async () => { // 선택 항목 필터링 실행
    if (selectedFiles.length === 0) {
      alert("선택된 항목이 없습니다.");
      return;
    }
    setLoading(true);
    try {
      // 선택된 파일에 대해 필터링 비동기 작업 수행
      for (const fileId of selectedFiles) {
        const file = searchResults.find((result) => result.file_id === fileId);
        if (file) {
          await handleFiltering(file.file_url); // 각 필터링 작업 대기
        }
      }
    } catch (error) {
      console.error("필터링 중 오류 발생:", error);
    } finally {
      setLoading(false); // 모든 작업 완료 후 비활성화 해제
    }
  };

  const indexOfLastResult2 = currentPage2 * resultsPerPage;
  const indexOfFirstResult2 = indexOfLastResult2 - resultsPerPage;
  const currentResults2 = userData.slice(
    indexOfFirstResult2,
    indexOfLastResult2
  );

  const totalPages2 = Math.ceil(userData.length / resultsPerPage);

  const handlePageChange2 = (pageNumber2) => {
    setCurrentPage2(pageNumber2);
  };

  return (
    <div className="download">
      <div className="search-options" style={{justifyContent:'start'}}>
        <p className="option-title" style={{marginRight:'40px', whiteSpace:'nowrap'}}>관리 항목</p>
        <label style={{marginLeft:'20px'}}>
          <input 
            type='radio' 
            value="파일관리" 
            checked={selectedOption === "파일관리"} 
            onChange={handleChange}
          />
          파일관리
        </label>
        <label style={{marginLeft:'20px'}}>
          <input 
            type='radio' 
            value="회원관리" 
            checked={selectedOption === "회원관리"} 
            onChange={handleChange}
          />
          회원관리
        </label>
      </div>
      {selectedOption === '파일관리' ? (
        <>
          <div className="search-options">
            <p className="option-title">검색 옵션 설정</p>
            <button className="toggle-button" onClick={toggleOptions}>
              <IoIosOptions size={20} style={{ marginRight: "8px" }} />
              옵션
            </button>
          </div>
          {showOptions && (
            <div className="options-container">
              <div className="option-group">
                <h3>기간</h3>
                <div className="option-buttons">
                  {periods.map((period) => (
                    <button
                      key={period}
                      className={`option-button ${
                        selectedPeriod === period ? "selected" : ""
                      }`}
                      onClick={() => handlePeriodSelect(period)}
                    >
                      {period}
                    </button>
                  ))}
                </div>
              </div>
              {code === '3' && (
                <div className="option-group">
                <h3>팀명</h3>
                <div className="option-buttons">
                  {teams.map((team) => (
                    <button
                      key={team}
                      className={`option-button ${
                        selectedTeam === team ? "selected" : ""
                      }`}
                      onClick={() => handleTeamSelect(team)}
                    >
                      {team}
                    </button>
                  ))}
                </div>
              </div>
              )} 
              <div className="options-footer">
                <button className="search-button" onClick={handleSearch}>
                  검색
                </button>
                <button className="reset-button" onClick={resetOptions}>
                  초기화
                </button>
              </div>
            </div>
          )}
          <div className="search-result">
            <div className="checkbutton">
              <button onClick={handleBulkFiltering} disabled={loading}>
                AI 필터링
              </button>
            </div>
            <table className="result-table">
              <thead>
                <tr>
                  <th>
                    <input 
                      type="checkbox"
                      onChange={handleSelectAll}
                      checked={isAllSelected}
                    />
                  </th>
                  <th>No</th>
                  <th>파일명</th>
                  <th>날짜</th>
                  <th>팀명</th>
                  <th>업로더</th>
                  <th>사람음성발생여부</th>
                  <th>다운로드</th>
                </tr>
              </thead>
              <tbody>
                {currentResults.map((result, index) => (
                  <tr key={result.file_id}>
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedFiles.includes(result.file_id)}
                        onChange={() => handleCheckboxChange(result.file_id)}
                      />
                    </td>
                    <td>{index + 1}</td>
                    <td>{result.filename}</td>
                    <td>{result.upload_date}</td>
                    <td>{result.team}</td>
                    <td>{result.uploader}</td>
                    <td>{is_person[result.is_person+1]}</td>
                    <td>
                      <button
                        className="download-button"
                        onClick={() => handleDownload(result.file_url)}
                      >
                        <IoMdDownload size={16} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="pagination">
              {Array.from({ length: totalPages }, (_, i) => (
                <button
                  key={i}
                  className={`page-button ${
                    currentPage === i + 1 ? "active" : ""
                  }`}
                  onClick={() => handlePageChange(i + 1)}
                >
                  {i + 1}
                </button>
              ))}
            </div>
          </div>
        </>
      ) : 
        <>
          <div className="search-options">
            <p className="option-title">회원 관리 옵션</p>
            <button className="toggle-button" onClick={toggleModal}>
              <IoPersonAdd size={16} style={{ marginRight: "8px" }} />
              회원 추가
            </button>
          </div>
          {showModal && (
            <div className="modal-overlay2">
              <div className="modal2" onClick={(e) => e.stopPropagation()}>
                <h3>회원 추가</h3>
                <label>
                  이름
                  <input
                    type="text"
                    name="name"
                    value={newUser.name}
                    onChange={handleInputChange}
                  />
                </label>
                <label>
                  아이디
                  <input
                    type="text"
                    name="userId"
                    value={newUser.userId}
                    onChange={handleInputChange}
                  />
                </label>
                <label>
                  비밀번호
                  <input
                    type="password"
                    name="password"
                    value={newUser.password}
                    onChange={handleInputChange}
                  />
                </label>
                <label>
                  팀명
                  <select name="team" value={newUser.team} onChange={handleInputChange}>
                    <option value="고라니">고라니</option>
                    <option value="너구리">너구리</option>
                    <option value="다람쥐">다람쥐</option>
                    <option value="비둘기">비둘기</option>
                  </select>
                </label>
                <label>
                  분류
                  <select name="userType" value={newUser.userType} onChange={handleInputChange}>
                    <option value="0">업로더 및 라벨러</option>
                    <option value="1">업로더</option>
                    <option value="2">라벨러</option>
                    <option value="3">관리자</option>
                  </select>
                </label>
                <div className="modal-footer">
                  <button onClick={handleAddUser}>추가</button>
                  <button onClick={toggleModal}>닫기</button>
                </div>
              </div>
            </div>
          )}
          <div className="search-result">
            <table className="result-table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>이름</th>
                  <th>아이디</th>
                  <th>팀명</th>
                  <th>분류</th>
                  <th>소리 업로드 개수</th>
                  <th>소리 분석 개수</th>
                  <th>회원삭제</th>
                </tr>
              </thead>
              <tbody>
                {currentResults2.map((result, index) => (
                  <tr key={result._id}>
                    <td>{index + 1}</td>
                    <td>{result.name}</td>
                    <td>{result.user_id}</td>
                    <td>{result.team}</td>
                    <td>{result.is_admin}</td>
                    <td>{result.count_upload}</td>
                    <td>{result.count_labeling}</td>
                    <td>
                      <button
                        className="download-button"
                        onClick={() => handleDeleteUser(result)}
                      >
                        <MdOutlineDeleteForever size={20} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="pagination">
              {Array.from({ length: totalPages2 }, (_, i) => (
                <button
                  key={i}
                  className={`page-button ${
                    currentPage2 === i + 1 ? "active" : ""
                  }`}
                  onClick={() => handlePageChange2(i + 1)}
                >
                  {i + 1}
                </button>
              ))}
            </div>
          </div>
        </>
      }
    </div>
  );
};