import React, {useState, useEffect, useRef } from "react";
import "./style.css";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import axios from "axios";
import image_background from "../../../static/img/image_background.png"
import { useNavigate } from "react-router-dom";
import fft from 'fft.js';

export const Labeling = () => {
  const [label, setLabel] = useState('');
  const [fileId, setFileId] = useState('');
  const [fileInfo, setFileInfo] = useState("");
  const [selected, setSelected] = useState(null);
  const [customInput, setCustomInput] = useState("");
  const [selectedSpeciesIndex, setSelectedSpeciesIndex] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [CheckOption, setCheckOption] = useState(false);
  const navigate = useNavigate();
  const userid = localStorage.getItem("userid");
  const _id = localStorage.getItem("_id");
  const code = localStorage.getItem("code");

  //소리 파일 변환
  const [fileKey, setFileKey] = useState("");
  const [presignedUrl, setPresignedUrl] = useState("")
  const previousFileKey = useRef(null);
  const previousPresignedUrl = useRef(null);

  const audioRef = useRef(null);
  const canvasOscilloscope = useRef(null);
  const canvasSpectrogram = useRef(null);

  const [audioBuffer, setAudioBuffer] = useState(null);
  const [scaleFactor, setScaleFactor] = useState(1);
  const [Frequency, setFrequency] = useState(1);
  const [maxFrequency, setmaxFrequency] = useState(0);

  useEffect(() => {
    if (code === '1') {
      alert("권한이 없습니다.");
      navigate("/dashboard/home");
    }

    // Flask의 /labeling 엔드포인트에 요청을 보냄
    axios
      .get("/api/labeling", { params: { currentIndex: currentIndex , _id : _id, checkoption : CheckOption } })
      .then((response) => {
        if (response.status === 200) {
          setLabel(response.data.file_name);
          setFileId(response.data.file_id);
          setFileInfo(response.data.file_info);
          const newFileKey = `uploads/${response.data.file_name}`;
          if (newFileKey !== previousFileKey.current) {
            previousFileKey.current = newFileKey;
            setFileKey(newFileKey);
          }
        }
      }) 
      .catch((error) => {
        setLabel("");
        setFileInfo("");
        console.error("라벨 데이터를 가져오는 중 오류 발생:", error);
      });

  }, [currentIndex, _id, CheckOption, code, navigate]);

  useEffect(() => {
    if (!fileKey) return;

    axios
      .get(`/api/proxy/${fileKey}`)
      .then((response) => {
        if (response.data.url !== previousPresignedUrl.current) {
          previousPresignedUrl.current = response.data.url;
          setPresignedUrl(response.data.url);
        }
      })
      .catch((error) => console.error("Failed to fetch presigned URL:", error));
  }, [fileKey]);

  useEffect(() => {
    if (!presignedUrl) return;
    const audioContext = new AudioContext();
    let audioUrl;
    
    // Presigned URL을 사용하여 오디오 데이터를 가져와 처리
    fetch(presignedUrl)
      .then((response) => response.arrayBuffer())
      .then((audioData) => {
        const blob = new Blob([audioData], { type: "audio/wav" });
        const audioUrl = URL.createObjectURL(blob);
        if (audioRef.current) {
          audioRef.current.src = audioUrl;
        }
        return audioContext.decodeAudioData(audioData);
      })
      .then((decodedBuffer) => {
        setAudioBuffer(decodedBuffer);
        adjustCanvasSize(canvasOscilloscope.current, canvasSpectrogram.current);
        drawWaveform(decodedBuffer);
        drawSpectrogram(decodedBuffer);

      })
      .catch((error) => console.error("Failed to process audio data:", error));

      return () => {
        audioContext.close(); // AudioContext 종료
        if (audioUrl) {
          URL.revokeObjectURL(audioUrl); // Blob URL 정리
        }
      };
  }, [presignedUrl]);

  useEffect(() => {
    const handleResize = () => {
        scaleCanvas(canvasOscilloscope.current);
        scaleCanvas(canvasSpectrogram.current);
    };

    window.addEventListener("resize", handleResize);

    return () => {
        window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scaleCanvas = (canvas) => {
    const parent = canvas.parentElement;
    if (!parent) {
        console.error("Parent element not found.");
        return;
    }

    const { width, height } = parent.getBoundingClientRect();

    // CSS 스타일로 크기 조정
    canvas.style.width = `${Math.floor(width)}px`;
    canvas.style.height = `${Math.floor(height)}px`;
  } ;


  const adjustCanvasSize = (canvas1, canvas2) => {
    const parent = canvas1.parentElement;
    const parent2 = canvas2.parentElement;
    if (!parent && !parent2) {
      console.error("Parent element not found.");
      return;
    }
  
    const { width: width1, height: height1 } = parent.getBoundingClientRect();
    const { width: width2, height: height2 } = parent2.getBoundingClientRect();
    if (width1 === 0 || height1 === 0 || width2 === 0 || height2 === 0 ) {
      console.error("Parent dimensions are invalid:", width1, height1, width2, height2);
      return;
    }
    console.log("canvas1:", width1, height1);
    console.log("canvas2:", width2, height2);
    const dpr = window.devicePixelRatio || 1;
  
    canvas1.style.width = `${Math.floor(width1)}px`;
    canvas1.style.height = `${Math.floor(height1)}px`;
    canvas2.style.width = `${Math.floor(width2)}px`;
    canvas2.style.height = `${Math.floor(height2)}px`;

    canvas1.width = Math.floor(width1 * dpr);
    canvas1.height = Math.floor(height1 * dpr);
    canvas2.width = Math.floor(width2 * dpr * 2);
    canvas2.height = Math.floor(height2 * dpr * 2);
  };

  const drawWaveform = (audioBuffer, scale = 1) => {
    const canvas = canvasOscilloscope.current;
    const ctx = canvas.getContext("2d");
    const data = audioBuffer.getChannelData(0); // 왼쪽 채널 데이터 가져오기

    const { width, height } = canvas;

    const gridColor = "#ccc"; // 격자선 색상
    const timeInterval = 1; // y축 시간 간격 (초)
    const sampleRate = audioBuffer.sampleRate;
  
    ctx.clearRect(0, 0, width, height);

    ctx.strokeStyle = gridColor;
    ctx.lineWidth = 0.5;
    ctx.beginPath();
    ctx.moveTo(0, height / 2);
    ctx.lineTo(width, height / 2);
    ctx.stroke();

    const secondsPerPixel = data.length / sampleRate / width; // 초당 픽셀
    for (let t = 0; t < data.length / sampleRate; t += timeInterval) {
        const x = t / secondsPerPixel;
        if (x >= width) break;

        ctx.beginPath();
        ctx.moveTo(x, 0);
        ctx.lineTo(x, height);
        ctx.stroke();
    }

    ctx.strokeStyle = "#5A6268"; // 파형 색상
    ctx.lineWidth = 1;
    ctx.beginPath();
  
    const step = Math.ceil(data.length / width);
    const amp = height / 2;
  
    for (let i = 0; i < width; i++) {
      const min = Math.min(...data.slice(i * step, (i + 1) * step)) * scale;
      const max = Math.max(...data.slice(i * step, (i + 1) * step)) * scale;
      ctx.moveTo(i, (1 + min) * amp);
      ctx.lineTo(i, (1 + max) * amp);
    }
    ctx.stroke();
  };
  
  const drawSpectrogram = (audioBuffer, scale = 1) => {
    const canvas = canvasSpectrogram.current;
    const ctx = canvas.getContext("2d");
    const dpr = window.devicePixelRatio || 1;
  
    const { width, height } = canvas;
  
    // dpr 적용된 실제 렌더링 크기
    const scaledWidth = width / (dpr* 2);
    const scaledHeight = height / (dpr* 2);
  
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.scale(dpr*2, dpr*2); // 스케일 설정
    ctx.clearRect(0, 0, width, height); // 기존 내용 제거
  
    const channelData = audioBuffer.getChannelData(0);
    const sampleRate = audioBuffer.sampleRate;
    const nyquistFrequency = sampleRate / 2;
    setmaxFrequency(nyquistFrequency);
  
    const fftSize = 2048;
    const hopSize = fftSize / 2;
    const numFrames = Math.floor(channelData.length / hopSize) - 1;
  
    const fftInstance = new fft(fftSize);
    const spectrumData = new Array(fftSize / 2).fill(0);
    const spectrogram = [];

    
  
    for (let i = 0; i < numFrames; i++) {
      const frame = channelData.slice(i * hopSize, i * hopSize + fftSize);
      fftInstance.realTransform(spectrumData, frame);
      fftInstance.completeSpectrum(spectrumData);
  
      const magnitudes = spectrumData.slice(0, fftSize / 2).map((v) => Math.log(Math.abs(v) + 1));
      spectrogram.push(magnitudes);
    }

    const minFrequency = 0;
    const maxFrequency = nyquistFrequency / scale; 
    const minIndex = Math.floor((minFrequency / nyquistFrequency) * (fftSize / 2));
    const maxIndex = Math.ceil((maxFrequency / nyquistFrequency) * (fftSize / 2));
  
    // dpr을 반영하여 xScale, yScale 계산
    const xScale = scaledWidth / numFrames; // 실제 스케일 기준
    const yScale = scaledHeight / (maxIndex - minIndex);
  
    for (let x = 0; x < numFrames; x++) {
      for (let y = 0; y < fftSize / 2; y++) {
        const intensity = spectrogram[x][y];
        const color = `rgb(${intensity * 255}, ${intensity * 100}, ${intensity * 50})`;
  
        ctx.fillStyle = color;
  
        // dpr을 고려한 좌표 설정
        ctx.fillRect(
          Math.round(x * xScale),
          Math.round(scaledHeight - y * yScale),
          Math.ceil(xScale), // 간격 없이 연결
          Math.ceil(yScale)
        );
      }
    }
    ctx.save();
    ctx.setTransform(1, 0, 0, 1, 0, 0);

    ctx.fillStyle = "white";
    ctx.font = "75px AppleSDGothicNeoB";
    ctx.textAlign = "left";

    // 최소 주파수 (0 Hz)
    ctx.fillText("0 Hz", 10, height - 20); // 하단에서 20px 위로 조정

    // 최대 주파수 (Nyquist 주파수)
    ctx.fillText(`${Math.round(maxFrequency)} Hz`, 10, 80); // 상단에서 120px 아래로 조정

    ctx.restore(); // 이전 스케일 상태 복원

  };

  useEffect(() => {
    if (audioBuffer) {
      drawWaveform(audioBuffer, scaleFactor);
    }
  }, [audioBuffer ,scaleFactor]);

  useEffect(() => {
    if (audioBuffer) {
      drawSpectrogram(audioBuffer, Frequency);
    }
  }, [audioBuffer, Frequency]);

  const handlePrevClick = () => {
    setCurrentIndex((currentIndex) => Math.max(currentIndex - 1, 0));
    setSelected(null);
    setSelectedSpeciesIndex(null);
    if (audioRef.current?.src) {
      URL.revokeObjectURL(audioRef.current.src); // 기존 Blob URL 해제
      audioRef.current.src = ""; // 오디오 src 초기화
    }
  };

  const handleNextClick = () => {
    setCurrentIndex((currentIndex) => currentIndex + 1);
    setSelected(null);
    setSelectedSpeciesIndex(null);
    if (audioRef.current?.src) {
      URL.revokeObjectURL(audioRef.current.src); // 기존 Blob URL 해제
      audioRef.current.src = ""; // 오디오 src 초기화
    }
  };

  const handleSubmitClick = async () => {
    const selectedKey = keys[selected];
    let selectedValue;

    if (selectedKey === "기타") {
      selectedValue = customInput;
    } else {
      selectedValue = groups[selectedKey][selectedSpeciesIndex];
    }
    
    try {
      const response = await axios.post('/api/labeling_submit', {
        file_id: fileId,
        user_id: userid,
        class_1: selectedKey, 
        class_2: selectedValue,
      }, {
        headers: {
          'Content-Type': 'application/json', // JSON 형식으로 전송
        },
        withCredentials: true, // 세션 사용을 위해 쿠키 허용
      });
    
      if (response.status === 200) {
        console.log('라벨링 업로드 성공');

        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
        });
        handleNextClick();

      } else {
        console.log('라벨링 업로드 실패');
      }
    } catch (error) {
      console.error('파일 업로드 중 오류 발생:', error);
    }
  };

  const groups = {
    매미류: ["털매미", "늦털매미", "말매미", "참깽깽매미", "유지매미", "참매미", "애매미", "쓰름매미", "소요산매미"],
    조류: [
      "큰기러기", "참새", "집비둘기", "까치", "붉은머리오목눈이", "큰고니", "멧비둘기", "직박구리", "박새", "쑥새", "되새",
      "방울새", "노랑턱멧새", "알락할미새", "딱새", "큰부리까마귀", "물까치", "오목눈이", "떼까마귀", "종다리", "파랑새", "새호리기"
    ],
    무미양서류: [
      "청개구리", "수원청개구리", "두꺼비", "맹꽁이", "황소개구리", "무당개구리", "큰산개구리", "참개구리", "옴개구리",
      "한국산개구리", "금개구리", "계곡산개구리", "물두꺼비"
    ],
    메뚜기목: [
      "여치 긴날개여치", "갈색여치", "애여치", "베짱이", "검은다리실베짱이", "긴날개중베짱이", "중베짱이", "실베짱이", "줄베짱이",
      "쌕쌔기", "긴꼬리쌕쌔기", "등줄어리쌕쌔기", "매부리", "왕귀뚜라미", "모대가리귀뚜라미", "알락귀뚜라미", "청솔귀뚜라미", 
      "극동뀌뚜라미", "방울벌레", "좀방울벌레", "알락방울벌레", "삽사리", "풀종다리"
    ],
    포유류: ["고라니", "고양이", "다람쥐", "멧돼지", "관박쥐", "집박쥐", "안주애기박쥐", "대륙큰수염박쥐", "문둥이박쥐"],
    기타: []
  };

  const keys = Object.keys(groups);

  const handleCustomInputChange = (e) => {
    setCustomInput(e.target.value);
  };


  const handleSelect = (index) => {
    setSelected(index);
    setSelectedSpeciesIndex(null);
    setCustomInput("");
  };

  const handleSpeciesSelect = (index) => {
    setSelectedSpeciesIndex(index);
  };

  const handleCheckboxChange = (e) => {
    setCheckOption(e.target.checked); // 체크박스 상태 업데이트
  };

  return (
    <>
      <div className="title-space">
        <div className="text-area">
          <h1>소리 분석</h1>
        </div>
        <div className="image-area">
          <img src={image_background} alt="background decoration" className="side-image"/>
        </div>
      </div>
      <div className="labeling-main">
        <div className="labeling">
          <div className="checkbox-container">
            <label>
              <input
                type="checkbox"
                className="checkbox"
                checked={CheckOption}
                onChange={handleCheckboxChange} // 체크 여부를 상태로 관리
              />
              내가 올린 파일만 라벨링 진행하기
            </label>
          </div>
          <div className="label-box">
            <button className="arrow-button" onClick={handlePrevClick}>
              <IoIosArrowBack size={40}/>
            </button>
            <div className="label">
              {label}
            </div>
            <button className="arrow-button" onClick={handleNextClick}>
              <IoIosArrowForward size={40}/>
            </button>
          </div>
          <div className="scale-box">
            <p>진폭 스케일 설정 : </p>
            <button className="scale-button" onClick={() => setScaleFactor(1)}>x1</button>
            <button className="scale-button" onClick={() => setScaleFactor(2)}>x2</button>
            <button className="scale-button" onClick={() => setScaleFactor(4)}>x4</button>
          </div>
          <div className="wave-box">
            <canvas ref={canvasOscilloscope} className="oscilloscope"></canvas>
          </div>
          <div className="scale-box">
            <p>관심 소리 영역 설정 : </p>
            <button className="scale-button2" onClick={() => setFrequency(1)}>0K~{maxFrequency/1000}K</button>
            <button className="scale-button2" onClick={() => setFrequency(3)}>0K~{maxFrequency/1000/3}K</button>
            <button className="scale-button2" onClick={() => setFrequency(6)}>0K~{maxFrequency/1000/6}K</button>
          </div>
          <div className="spectrogram-box">
            <canvas ref={canvasSpectrogram} className="spectrogram"></canvas>
          </div>
          <div className="audio-player" onContextMenu={(e) => e.preventDefault()}>
            {presignedUrl ? (
              <audio 
                style={{width:'100%', marginBottom: '10px'}} 
                ref={audioRef} 
                controls 
                crossOrigin="anonymous" 
                controlsList="nodownload"
              ></audio>
            ) : (
              <p>음성 파일 로딩중입니다.</p>
            )}
          </div>
          { fileInfo && (
            <div className="label-info">*해당 소리에 대한 업로더의 메모 : {fileInfo}</div>
          )}
          <div className="question-box">
            <div className="button-title">
              Q1: 무슨 분류군일까요?
            </div>
            <div className="button-group-wrapper">
              <div className="button-group">
                {["매미류", "조류", "무미양서류", "메뚜기류", "포유류", "기타"].map((label, index) => (
                  <button
                    key={index}
                    className={`button-item ${selected === index ? "selected" : ""}`}
                    onClick={() => handleSelect(index)}
                  >
                    {index + 1}) {label}
                  </button>
                ))}
              </div>
            </div>
          </div>

          <div className="question-box">
            <div className="button-title">
              Q2: 무슨 종일까요?
            </div>
            {selected !== null && (
              <div className="species-group-wrapper">
                {selected !== 5 ? (
                  // 선택된 분류군에 따라 종 목록 표시
                  groups[Object.keys(groups)[selected]]?.map((species, index) => (
                    <div
                      key={index}
                      className={`species-item ${selectedSpeciesIndex === index ? "selected" : ""}`}
                      onClick={() => handleSpeciesSelect(index)}
                    >
                      {species}
                    </div>
                  ))
                ) : (
                  // "기타" 선택 시 사용자가 직접 입력할 수 있는 필드 표시
                  <input
                    type="text"
                    value={customInput}
                    placeholder="직접 입력해주세요."
                    onChange={handleCustomInputChange}
                    className="custom-input"
                  />
                )}
              </div>
            )}
          </div>
          <div className="button-box">
            <button className="submit-button" onClick={handleSubmitClick}>
              완료
            </button>
          </div>
        </div>
      </div>
    </>
  );
};